import(/* webpackMode: "eager", webpackExports: ["PageContainer"] */ "/vercel/path0/components/PageContainer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/PreviewProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VisualEditingView"] */ "/vercel/path0/components/VisualEditingView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PlayerProvider"] */ "/vercel/path0/lib/playerContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/vercel/path0/lib/settingsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WindowProvider"] */ "/vercel/path0/lib/windowContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles.css");
